import React from "react";
import Header from "./Header";
import MainContent from "./MainContent";
import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";

let theme = createMuiTheme({
  typography: {
    fontFamily: ["Poppins", "sans-serif"],
    body1: {
      fontSize: "14px",
    },
    subtitle1: {
      fontFamily: "Open Sans",
      fontWeight: "600",
      fontSize: "18px",
      color: "#109CF1",
    },
    caption: {
      fontSize: "12px",
      color: "#818E9B",
    },
  },
  palette: {
    primary: {
      main: "#109CF1",
    },
    secondary: {
      main: "#F7685B",
    },
  },
});
theme = responsiveFontSizes(theme);

function App() {
  const [open, setOpen] = React.useState(false);
  const [openNfo, setOpenNfo] = React.useState(false);
  const [drawerNfo, setDrawerNfo] = React.useState([{}]);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [totalContracts, setTotalContracts] = React.useState(10);
  const [searchQuery, setSearchQuery] = React.useState("");
  
  const [category, setCategory] = React.useState("MatchForce Awards");
  const milisecondsInDay = 86400000;
  const dayInMonth = 30;
  const currentDate = new Date()
  const pre_month = new Date(+currentDate - dayInMonth * milisecondsInDay);
  const [filterDateFrom, setFilterDateFrom] = React.useState(pre_month);

  // const [filterDateFrom, setFilterDateFrom] = React.useState(new Date());
  const [filterDateTo, setFilterDateTo] = React.useState(new Date());
  const [mainData, setMainData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [filterBySolNumb, setFilterBySolNumb] = React.useState('');
  const [filterCategory, setFilterCategory] = React.useState('MatchForce Awards');
  const [selectedColums, setSelectedColums] = React.useState([
    { name: "Contract Title", status: true },
    { name: "City", status: true },
    { name: "Contract", status: true },
    { name: "Solicitation", status: true },
    { name: "Source", status: true },
    { name: "Amount", status: true }
  ]);
  const mobileMenuId = "menu-mobile";
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDrawerOpenNfo = (obj) => {
    setDrawerNfo(obj);
    setOpenNfo(true);
  };
  const handleDrawerCloseNfo = () => {
    setOpenNfo(false);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Header
          open={open}
          handleDrawerOpen={handleDrawerOpen}
          handleDrawerClose={handleDrawerClose}
          mobileMenuId={mobileMenuId}
          mobileMoreAnchorEl={mobileMoreAnchorEl}
          handleMobileMenuOpen={handleMobileMenuOpen}
          handleMobileMenuClose={handleMobileMenuClose}
          setSearchQuery={setSearchQuery}
					searchQuery={searchQuery}
					filterCategory={filterCategory}
					setFilterCategory={setFilterCategory}
					setFilterDateTo={setFilterDateTo}
					setFilterDateFrom={setFilterDateFrom}
        />
        <MainContent
          open={open}
          openNfo={openNfo}
          drawerData={drawerNfo}
          handleDrawerOpenNfo={handleDrawerOpenNfo}
          handleDrawerCloseNfo={handleDrawerCloseNfo}
          totalContracts={totalContracts}
          setTotalContracts={setTotalContracts}
          searchQuery={searchQuery}
          selectedColums={selectedColums}
					setSelectedColums={setSelectedColums}
					category={category}
					setCategory={setCategory}
					filterDateTo={filterDateTo}
					setFilterDateTo={setFilterDateTo}
					filterDateFrom={filterDateFrom}
					setFilterDateFrom={setFilterDateFrom}
					mainData={mainData}
					setMainData={setMainData}
					loading={loading}
					setLoading={setLoading}
					filterBySolNumb={filterBySolNumb}
					setFilterBySolNumb={setFilterBySolNumb}
					filterCategory={filterCategory}
					setFilterCategory={setFilterCategory}
        />
      </ThemeProvider>
    </div>
  );
}

export default App;
