import React from "react";
import { makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CallToActionIcon from "@material-ui/icons/CallToAction";

const useStyles = makeStyles((theme) => ({
  toggleDrawer: {
		display: "flex",
		padding:'20px 0',
    "& :hover": {
      cursor: "pointer",
    },
	},
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  }
}))

export default function DrawerToggler(props) {
  const classes = useStyles();
  return (
    <div className={`${classes.drawerHeader} ${classes.toggleDrawer}`}>
      <div
        style={{ display: "flex", alignItems: "center" }}
        onClick={props.handleDrawerClose}
      >
        <>
          <CallToActionIcon
            style={{ transform: "rotate(90deg)", marginRight: "10px",color:'#C4C4C4'}}
          />
          <Typography variant="body1" style={{color:'#C4C4C4',fontSize:'11px'}}>Toggle sidebar</Typography>
        </>
      </div>
    </div>
  );
}
