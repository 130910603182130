import React from "react";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

export default function customField({ label, data, icon = null, link = null }) {
  return (
    <ListItem>
      <div
        style={{
          paddingBottom: "6px",
          borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
          width: "100%",
        }}
      >
        <Typography
          variant="caption"
          style={{ marginBottom: "6px" }}
          display="block"
        >
          {label}
        </Typography>
        <div>
          {!link ? (
            <Typography variant="body1" style={{
							display:'flex',
							justifyContent: 'space-between',
							alignItems:'center',
							minHeight:'21px'
						}}>
              {data}
              {icon}
            </Typography>
          ) : (
            <Link href={link} target="_blank">
              Click Here
            </Link>
          )}
        </div>
      </div>
    </ListItem>
  );
}
