import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 620,
  },
  singleRow: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  cellStyle: {
    fontWeight: 400,
    color: "#707683",
		fontSize: "14px",
		textAlign: "center"
  },
});

export default function StickyHeadTable({
  handleDrawerOpenNfo,
  totalContracts,
  setTotalContracts,
  selectedColums,
  mainData,
  searchQuery,
  setExportData,
  setFilterBySolNumb,
}) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const tableData = mainData.map((el) => {
    return {
      amount: el.amount,
      city: el.city_name,
      source: el.department,
      naics: el.naics,
      solicitation: el.sol_no,
      name: el.title,
      contract: el.contract_no,
      duns: el.duns,
      uei: el.uei,
      tax_id: el.tax_id,
      title: el.name,
      email: el.email,
      phone: el.phone,
      //previous links
      // country: el.location.country_name,
      // duns: el.duns,
      // sam_link: el.sam_link,
      // date: el.date,
      // vendor: el.business_name
    };
  });
  const [localData, setLocalData] = React.useState(tableData);
  const queryRows = tableData.filter((x) => {
    for (const i in x) {
      if (x[i]) {
        if (x[i].toString().toLowerCase().includes(searchQuery)) {
          return x;
        }
      }
    }
  });

  useEffect(() => {
    setLocalData(queryRows);
    setExportData(queryRows);
  }, [searchQuery]);

  useEffect(() => {
    setTotalContracts(totalContracts);
    setPage(0);
  }, [totalContracts]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setTotalContracts(+event.target.value);
    setPage(0);
  };
  const currentContractHandler = (param) => {
    setFilterBySolNumb(param.solicitation);
    handleDrawerOpenNfo({
      ...param,
    });
  };
  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead style={{ background: "#fff" }}>
            <TableRow>
              {selectedColums.map((el) =>
                el.status ? (
                  <TableCell
										key={el.sol_no}
										style={ el.name==="Contract Title" ? {width:'300px'} : {whiteSpace: 'nowrap',textAlign:'center'}}
                  >
                    {el.name}
                  </TableCell>
                ) : null
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {localData
              .slice(
                page * totalContracts,
                page * totalContracts + totalContracts
              )
              .map((row) => {
                return (
                  <TableRow
                    onClick={() => currentContractHandler(row)}
                    className={classes.singleRow}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row["solicitation"]}
                  >
                    {selectedColums.map((element) => {
                      if (element.name === "Contract Title") {
                        return element.status ? (
                          <TableCell key={row["name"] + "-title"}>
                            <p
                              style={{
                                fontWeight: 500,
                                color: "#323C47",
                                fontSize: "16px",
                                // width: "500px",
                              }}
                            >
                              {row["name"] ? row["name"] : <span style={{color:'#d34356'}}>No data</span>}
                            </p>
                          </TableCell>
                        ) : null;
                      } else if (element.name === "City") {
                        return element.status ? (
                          <TableCell
                            className={classes.cellStyle}
                            key={row["city"] + "-city"}
                          >
                            <p
                              style={{
                                fontSize: "14px",
                                // width: "120px",
                              }}
                            >
                              {row["city"] ? row["city"] : <span style={{color:'#d34356'}}>No data</span>}
                            </p>
                          </TableCell>
                        ) : null;
                      } else if (element.name === "Contract") {
                        return element.status ? (
                          <TableCell
                            className={classes.cellStyle}
                            key={row["contract_no" + "-contract_no"]}
                          >
                            <p
                              style={{
                                fontSize: "14px",
                                // width: "300px",
                              }}
                            >
                              {row["contract"] ? row["contract"] : <span style={{color:'#d34356'}}>No data</span>}
                            </p>
                          </TableCell>
                        ) : null;
                      } else if (element.name === "Solicitation") {
                        return element.status ? (
                          <TableCell
                            className={classes.cellStyle}
                            key={row["solicitation"] + "-solicitation"}
                          >
                            <p
                              style={{
                                fontSize: "14px",
                                // width: "200px",
                              }}
                            >
                              {row["solicitation"] ? row["solicitation"] : <span style={{color:'#d34356'}}>No data</span>}
                            </p>
                          </TableCell>
                        ) : null;
                      } else if (element.name === "Source") {
                        return element.status ? (
                          <TableCell
                            className={classes.cellStyle}
														key={row["source"] + "-source"}
														style={{width:"200px"}}
                          >
                            <p
                              style={{
                                fontSize: "14px",
                                // width: "300px",
                              }}
                            >
                              {row["source"] ? row["source"] : <span style={{color:'#d34356'}}>No data</span>}
                            </p>
                          </TableCell>
                        ) : null;
                      } else if (element.name === "Amount") {
                        return element.status ? (
                          <TableCell
                            className={classes.cellStyle}
                            key={row["amount"] + "-amount"}
                          >
                            <p
                              style={{
                                color: "#323C47",
                                fontSize: "14px",
                                // width: "150px",
                              }}
                            >
                              {row["amount"] ? row["amount"] : <span style={{color:'#d34356'}}>No data</span>}
                            </p>
                          </TableCell>
                        ) : null;
                      } else if (element.name === "Duns") {
                        return element.status ? (
                          <TableCell
                            className={classes.cellStyle}
                            key={row["duns"] + "-duns"}
                          >
                            <p
                              style={{
                                color: "#323C47",
                                fontSize: "14px",
                                // width: "150px",
                              }}
                            >
                              {row.duns ? row["duns"] : <span style={{color:'#d34356'}}>No data</span>}
                            </p>
                          </TableCell>
                        ) : null;
                      } else if (element.name === "UEI") {
                        return element.status ? (
                          <TableCell
                            className={classes.cellStyle}
                            key={row["uei"] + "-uei"}
                          >
                            <p
                              style={{
                                color: "#323C47",
                                fontSize: "14px",
                                // width: "150px",
                              }}
                            >
                              {row.uei ? row["uei"] : <span style={{color:'#d34356'}}>No data</span>}
                            </p>
                          </TableCell>
                        ) : null;
                      } else if (element.name === "Tax ID") {
                        return element.status ? (
                          <TableCell
                            className={classes.cellStyle}
														key={row["tax_id"] + "-tax_id"}
														style={{ whiteSpace: 'nowrap'}}
                          >
                            <p
                              style={{
                                color: "#323C47",
                                fontSize: "14px",
                                // width: "150px",
                              }}
                            >
                              {row["tax_id"] && row["tax_id"] !== "null" ? row["tax_id"] : <span style={{color:'#d34356'}}>No data</span>}
                            </p>
                          </TableCell>
                        ) : null;
                      } else if (element.name === "Contact info") {
                        return element.status ? (
                          <TableCell
                            className={classes.cellStyle}
														key={row["email"] + "-contact"}
														style={{ whiteSpace: 'nowrap'}}
                          >
                            <p
                              style={{
                                color: "#323C47",
                                fontSize: "12px",
                              }}
                            >
                              <span style={{fontSize:'10px'}}>Name:</span> <br />
                              {row["title"] ? row["title"] : <span style={{color:'#d34356'}}>No data</span>} <br />
                              <span style={{fontSize:'10px'}}>Email:</span> <br />
                              {row["email"] ? row["email"] : <span style={{color:'#d34356'}}>No data</span>}
                              <br />
                              <span style={{fontSize:'10px'}}>Phone:</span> <br />
                              {row["phone"] ? row["phone"] : <span style={{color:'#d34356'}}>No data</span>}
                            </p>
                          </TableCell>
                        ) : null;
                      }
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 50, 100]}
        component="div"
        count={localData.length}
        rowsPerPage={totalContracts}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
