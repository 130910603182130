import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
// import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
// import FormControl from "@material-ui/core/FormControl";
// import Select from "@material-ui/core/Select";
// import Typography from "@material-ui/core/Typography";
import Menu from "@material-ui/core/Menu";
import Checkbox from "@material-ui/core/Checkbox";
import ViewColumnOutlinedIcon from "@material-ui/icons/ViewColumnOutlined";
import { FormControl, InputLabel, Select, Typography } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: "20px",
    justifyContent: "space-between",
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
  formControl: {
    minWidth: 200,
    position: "static",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  parentForm: {
    display: "flex",
    alignItems: "flex-end",
    marginLeft: "45px",
    position: "relative",
    opacity: "0.5",
  },
  leftSide: {
    display: "flex",
    alignItems: "flex-end",
  },
  myBtn: {
    textTransform: "initial",
    fontSize: "14px",
    opacity: 0.5,
    width: "171px",
    borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
    borderRadius: 0,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    marginLeft: "50px",
    lineHeight: "1",
  },
}));



export default function SpacingGrid({
  setTotalContracts,
  setSelectedColums,
  selectedColums,
	filterDateTo,
	setFilterDateTo,
	filterDateFrom,
	setFilterDateFrom,
	mainDataLength,
	filterCategory,
	setFilterCategory
}) {
	const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [flag, setFlag] = React.useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
	};
	const handleChange = (event) => {
		const milisecondsInDay = 86400000;
		const dayInMonth = 30;
		const currentDate = new Date()
		// setFilterDateTo(new Date(`2021-${currentDate.getMonth() + 1}-${currentDate.getDate()}`))
    new Date(+currentDate - dayInMonth * milisecondsInDay)
		// if(event.target.value === "All"){
    //   // setFilterDateFrom(new Date(+currentDate - dayInMonth * milisecondsInDay));
    //   setFilterDateFrom(new Date(`2022-${currentDate.getMonth() + 1}-${currentDate.getDate()}`))
		// } else if(event.target.value === "MatchForce Awards"){
    //   setFilterDateFrom(new Date(+currentDate - dayInMonth * milisecondsInDay));
    // }
		setFilterCategory(event.target.value);
	};
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleAllSelect = () => {
    setTotalContracts(mainDataLength);
  };
  const handleColumnChange = (element) => {
    const newArr = [];
    selectedColums.forEach((el) => {
      if (el["name"] === element["name"]) {
        newArr.push({
          ...element,
          status: !el["status"],
        });
      } else {
        newArr.push(el);
      }
    });
    setSelectedColums(newArr);
  };

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item className={classes.leftSide}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid style={{marginRight:'25px'}} container justify="space-around">
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              id="date-picker-inline1"
              label="Select Date From"
              value={filterDateFrom}
              onChange={setFilterDateFrom}
              style={{ opacity: ".5" }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </Grid>
        </MuiPickersUtilsProvider>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container justify="space-around">
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              id="date-picker-inline2"
              label="Select Date To"
              value={filterDateTo}
              onChange={setFilterDateTo}
              style={{ opacity: ".5" }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </Grid>
        </MuiPickersUtilsProvider>
        <div>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
            className={classes.myBtn}
          >
            Columns
            <ViewColumnOutlinedIcon
              style={{
                fontSize: "30px",
                transform: "translateY(10px)",
                color: "rgba(0, 0, 0, 0.54)",
              }}
            />
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            {selectedColums.map((el) => (
              <MenuItem onClick={() => handleColumnChange(el)} key={el.name}>
                <Checkbox
                  color="primary"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                  checked={el.status}
                />
                {el.name}
              </MenuItem>
            ))}
          </Menu>
        </div>
				<div className={classes.parentForm}>
          <Typography
            style={{ marginRight: "20px", paddingBottom: "5px" }}
            variant="body1"
            noWrap
          >
            Filter
          </Typography>
          <FormControl className={classes.formControl}>
            <InputLabel id="select-label"></InputLabel>
            <Select
              labelId="select-label"
              id="select"
              style={{
                position: "static",
                marginTop: 0,
                fontWeight: "bold",
                color: "#109CF1",
              }}
              value={filterCategory}
              defaultValue={"All"}
              onChange={handleChange}
            >
             <MenuItem value={"MatchForce Awards"}>MatchForce Awards</MenuItem>
			   <MenuItem value={"All"}>All</MenuItem>
            
              {/* <MenuItem value={"BDS Team Awards"}>BDS Team Awards</MenuItem> */}
            </Select>
          </FormControl>
        </div>
      </Grid>
      <Grid item>
        <Button
          onClick={() => handleAllSelect()}
          style={{ color: "#fff" }}
          size="large"
          variant="contained"
          color="primary"
        >
          VIEW ALL AWARDS
        </Button>
      </Grid>
    </Grid>
  );
}
