import React, { useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Backdrop from "@material-ui/core/Backdrop";
import Divider from "@material-ui/core/Divider";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import NotificationsIcon from "@material-ui/icons/Notifications";
// import TextField from "@material-ui/core/TextField";
// import Button from "@material-ui/core/Button";
import { swaggerApi } from "../../api/swagger";

import User from "./User";
import DrawerMenuTitle from "./DrawerMenuTitle";
import BusinessType from "./BusinessType";
import Settings from "./Settings";
import ShowPeriod from "./ShowPeriod";
import Progress from "./Progress";
import DrawerToggler from "./DrawerToggler";
// import { Typography } from "@material-ui/core";

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  drawer: {
    flexShrink: 0,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  drawerPaper: {
    justifyContent: "space-between",
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer - 1,
    color: "#fff",
  },
  root: {
    "& > *": {
      margin: "10px 16px",
      width: "220px",
      maxWidth: "100%",
    },
  },
}));
export default function DrawerMenu({
  mobileMoreAnchorEl,
  mobileMenuId,
  handleMobileMenuClose,
  width,
  isOpen,
  handleDrawerClose,
  filterCategory,
  setFilterCategory,
  setFilterDateTo,
  setFilterDateFrom,
}) {
  const classes = useStyles();
  const currentDate = new Date();
  const formatedTime = `${currentDate.getFullYear()}-${
    currentDate.getMonth().toString().length !== 1
      ? currentDate.getMonth() + 1
      : currentDate.getMonth() + 1
  }-${
    currentDate.getDate().toString().length !== 1
      ? currentDate.getDate()
      : "0" + currentDate.getDate()
  }`;
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [totalAmount, setTotalAmount] = React.useState("0");
  const [totalAwards, setTotalAwards] = React.useState("0");
  const [loading, setLoading] = React.useState(false);
  const [timePeriod, setTimePeriod] = React.useState({
    key: "day",
    time: formatedTime,
	});

  useEffect(() => {
    setLoading(true);
    setTotalAmount("... loading");
		setTotalAwards("... loading");
		const route = filterCategory === 'All' ? '/awards-total': '/mf-awards-total';
    swaggerApi
      .post(route, {
        period: timePeriod.key,
      })
      .then(function (response) {
        const amount = response.data[0];
        const awards = response.data[1];
        setTotalAmount(Object.values(awards)[0] + "");
        setTotalAwards(Object.values(amount)[0] + "");
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        setTotalAmount("No data");
        setTotalAwards("No data");
      });
	}, [timePeriod]);
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={17} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
    </Menu>
  );
  return (
    <div style={{ overflow: "hidden" }}>
      <Drawer
        style={{ width }}
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={isOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <DrawerMenuTitle category={filterCategory} />
        <Divider />
        {/* <User /> */}
        <Divider />
        <BusinessType
          filterCategory={filterCategory}
          setFilterCategory={setFilterCategory}
          setFilterDateTo={setFilterDateTo}
          setFilterDateFrom={setFilterDateFrom}
        />
        <Divider />
        {/* <Settings /> */}
        <Divider />
        <div style={{ flexGrow: 2 }}></div>
        <Divider />
        <ShowPeriod filterCategory={filterCategory} timePeriod={timePeriod} setTimePeriod={setTimePeriod} />
        <Divider />
        <Progress
          loading={loading}
          totalAmount={totalAmount}
          totalAwards={totalAwards}
        />
        <Divider />
        {/* <form className={classes.root} noValidate autoComplete="off">
				<Typography variant="body2" style={{marginRight:'25px',fontSize:'16px'}}>Search for an award</Typography>
          <TextField id="phone" label="Phone number" />
          <TextField id="email" label="Email" />
          <TextField id="duns" label="DUNS #" />
          <TextField id="tax" label="Tax ID" />
          <Button
            onClick={() => console.log('hello')}
            style={{ color: "#fff" }}
            size="large"
            variant="contained"
            color="primary"
          >
            Search
          </Button>
        </form> */}
        <DrawerToggler handleDrawerClose={handleDrawerClose} />
      </Drawer>
      <Backdrop
        className={classes.backdrop}
        open={isOpen}
        onClick={handleDrawerClose}
        style={{ maxWidth: "100%" }}
      >
        {renderMobileMenu}
      </Backdrop>
    </div>
  );
}
