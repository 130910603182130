import React, { useEffect } from "react";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";




export default function ShowPeriod(
	{timePeriod,setTimePeriod, filterCategory}
) {
	const deltaDate = (input, days, months, years) => {
		const test = new Date(
			input.getFullYear() + years, 
			input.getMonth() + months, 
			Math.min(
				input.getDate() + days,
				new Date(input.getFullYear() + years, input.getMonth() + months + 1, 0)
			)
		);
		const formatedTime = `${test.getFullYear()}-${
      test.getMonth().toString().length !== 1
        ? test.getMonth() + 1
        : test.getMonth() + 1
    }-${
      test.getDate().toString().length !== 1
        ? test.getDate()
        : "0" + test.getDate() 
    }`;
		return formatedTime
	}
  const handleChange = (event) => {
		setTimePeriod({
			key: event.target.value,
      time: deltaDate(new Date(), 0, -event.target.value ,0),
		});
	};
	useEffect(() => {
		console.log(filterCategory);
    setTimePeriod({
      key: 'month'
    });
		// if(filterCategory == 'All'){
		// 	setTimePeriod({
    //     key: 'month',
		// 	});
    //   console.log(timePeriod.key);
		// } else if (filterCategory == 'MatchForce Awards') {
		// 	setTimePeriod({
		// 		key: 'month'
		// 	});
    //   console.log(timePeriod.key);
		// }
	}, [filterCategory])
  return (
    <List>
      <ListItem
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        key={"Settings"}
      >
        <Typography style={{fontWeight:'500',fontSize:'15px'}}>Total</Typography>
        <div
          className="chooseDate"
          style={{ display: "flex", alignItems: "center",fontSize:'12px' }}
        >
          <Typography style={{ marginRight: "5px",fontSize:'inherit' }}>Show</Typography>
          <FormControl>
            <Select
              value={timePeriod.key}
              onChange={handleChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
							style={{ width: "100px",fontSize:'12px',color:'#109CF1' }}
							
            >
              <MenuItem value={"day"} >This day</MenuItem>
              <MenuItem value={"month"}>This month</MenuItem>
              <MenuItem value={"month_3"}>3 months</MenuItem>
              <MenuItem value={"month_6"}>6 months</MenuItem>
              <MenuItem value={"year"}>year</MenuItem>
            </Select>
          </FormControl>
        </div>
      </ListItem>
    </List>
  );
}
