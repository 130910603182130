import React from "react";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";

import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";

import DrawerMenu from "../DrawerMenu";
import HeaderToolbar from "./HeaderToolbar/HeaderToolbar";

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    position: "relative",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
		}),
		background:'#fff',
		color: '#90A0B7',
		height: '60px'
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

export default function PersistentDrawerLeft({
  open,
  handleDrawerOpen,
  handleDrawerClose,
  mobileMenuId,
  mobileMoreAnchorEl,
  handleMobileMenuOpen,
	handleMobileMenuClose,
	setSearchQuery,
	searchQuery,
	filterCategory,
	setFilterCategory,
	setFilterDateTo,
	setFilterDateFrom
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="relative"
        style={{ marginBottom: "50px" }}
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <HeaderToolbar
          handleMobileMenuOpen={handleMobileMenuOpen}
          mobileMenuId={mobileMenuId}
          handleDrawerOpen={handleDrawerOpen}
					isOpen={open}
					setSearchQuery={setSearchQuery}
					searchQuery={searchQuery}
        />
      </AppBar>
      <DrawerMenu
        width={drawerWidth}
        isOpen={open}
        mobileMenuId={mobileMenuId}
        handleMobileMenuClose={handleMobileMenuClose}
        handleDrawerClose={handleDrawerClose}
				mobileMoreAnchorEl={mobileMoreAnchorEl}
				filterCategory={filterCategory}
				setFilterCategory={setFilterCategory}
				setFilterDateTo={setFilterDateTo}
				setFilterDateFrom={setFilterDateFrom}
      />
    </div>
  );
}
