import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import image from "./cup.png";

const useStyles = makeStyles((theme) => ({
  bottom: {
    color: "#109cf1",
  },
  top: {
    color: "#2ed47a",
    animationDuration: "550ms",
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%) rotate(90deg)!important",
  },
  circle: {
    strokeLinecap: "round",
  },
  totalAwards: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  circleCont: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    width: "100%",
    padding: "30px 0",
  },
  circleShadow: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%,-50%)",
    width: "95px",
    height: "93px",
    background: "#fff",
    boxShadow: "0px 2px 9px 3px rgba(0, 0, 0, 0.09)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
  },
}));

export default function ShowPeriod({ totalAwards, totalAmount, loading }) {
	const classes = useStyles();
	const [circleValue, setCircleValue] = React.useState(0);
	useEffect(() => {
		const timer = setTimeout(() => {
			setCircleValue(val => val + 50)
		}, 50);
		return () => clearTimeout(timer);
  }, [loading]);
  return (
    <>
      <div className={classes.circleCont}>
        <CircularProgress
          variant="static"
          className={classes.bottom}
          size={150}
          thickness={4}
          value={100}
        />
        <div className={classes.circleShadow}>
          <img src={image} alt="icon" />
        </div>
        <CircularProgress
          variant="static"
          className={classes.top}
          classes={{
            circle: classes.circle,
          }}
          size={150}
          thickness={4}
          value={circleValue}
        />
      </div>
      <List style={{ paddingBottom: "20px" }}>
        <ListItem>
          <div className={classes.totalAwards}>
            <Typography
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "12px",
                color: "#828282",
              }}
            >
              <RadioButtonUncheckedIcon
                style={{ marginRight: "7px", color: "#109CF1" }}
              />
              AWARDS
            </Typography>
            <Typography
              variant="body1"
              style={{ fontSize: "14px", color: "#828282", fontWeight: "600" }}
            >
              {totalAwards}
            </Typography>
          </div>
        </ListItem>
        <ListItem>
          <div className={classes.totalAwards}>
            <Typography
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "12px",
                color: "#828282",
              }}
            >
              <RadioButtonUncheckedIcon
                style={{ marginRight: "7px", color: "#2ED47A" }}
              />
              AMOUNT
            </Typography>
            <Typography
              variant="body1"
              style={{ fontSize: "14px", color: "#828282", fontWeight: "600" }}
            >
              {totalAmount}
            </Typography>
          </div>
        </ListItem>
      </List>
    </>
  );
}
