import React from "react";
import Typography from "@material-ui/core/Typography";

export default function DrawerToggler({category}) {
  return (
    <div style={{padding:'17px 16px',height:'60px',display:'flex',alignItems:'center'}}>
			<Typography variant="subtitle1" noWrap>{category}</Typography>
		</div>
  );
}
