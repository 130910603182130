import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Backdrop from "@material-ui/core/Backdrop";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import "date-fns";
import CustomField from "./CustomField";
import { swaggerApi } from "../../api/swagger";

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer - 1,
    color: "#fff",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    minHeight: "initial!important",
    height: "62px!important",
    justifyContent: "space-between",
    width: "100%",
    padding: "17px 16px",
  },
  inputs: {
    width: "100%",
  },
  twoInputs: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: '100%'
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  }
}));

const fieldValidate = (field) => {
  if (field) {
    console.log(field)
    return field
  } else {
    return <span style={{ color: '#d34356' }}>No data</span>
  }
}

export default function DrawerToggler({
  isOpen,
  handleDrawerCloseNfo,
  drawerData,
  filterBySolNumb
}) {
  const classes = useStyles();
  const [nfoDrawer, setNfoDrawer] = React.useState({});
  useEffect(() => {
    setNfoDrawer({})
    if (filterBySolNumb) {
      swaggerApi
        .post("/awards-details", {
          "sol_no": "" + filterBySolNumb
        })
        .then(function (response) {
          if (response.data[0]){
            setNfoDrawer(response.data[0]);
          } else {
            let nullResponse = [
              {
                "title": "-",
                "city_name": "-",
                "country_name": "-",
                "notice_id": "-",
                "sol_no": "-",
                "department": "-",
                "amount": "-",
                "duns": null,
                "uei": null,
                "naics": null,
                "sam_link": "#",
                "award_date": "-",
                "business_name": "-",
                "contract_no": "-",
                "history_link": null
              }
            ]
            setNfoDrawer(nullResponse[0]);
          }

        })
        .catch(function (error) {
          console.log(error)
        });
    } else {
      return
    }
  }, [filterBySolNumb])
  return (
    <>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={isOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>

          {
            nfoDrawer["title"] ?
              <Typography variant="subtitle1" noWrap>
                {fieldValidate(nfoDrawer["title"])}
              </Typography>
              :
              <Typography variant="subtitle1">-</Typography>
          }
          <IconButton style={{ padding: 0 }} onClick={handleDrawerCloseNfo}>
            <CloseIcon />
          </IconButton>
        </div>
        <Divider />
        <List
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            padding: "30px 0 120px",
          }}
        >
          <CustomField label="City" data={fieldValidate(nfoDrawer["city_name"])} />
          <CustomField label="County" data={fieldValidate(nfoDrawer["country_name"])} />
          <CustomField label="Vendor" data={fieldValidate(nfoDrawer["business_name"])} />
          <CustomField label="Contract Amount" data={fieldValidate(nfoDrawer["amount"])} />
          <CustomField
            label="Solicitation Number"
            data={fieldValidate(nfoDrawer["sol_no"])}
          />
          <CustomField label="Contract Number" data={fieldValidate(nfoDrawer["contract_no"])} />
          {
            nfoDrawer["department"] ?
              <CustomField label="Source" data={fieldValidate(nfoDrawer["department"])} />
              : <CustomField label="Source" data={fieldValidate(nfoDrawer["department"])} />
          }
          <ListItem>
            <div className={classes.title}>
              <div
                style={{
                  paddingBottom: "6px",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
                  width: "100%",
                }}
              >
                <Typography
                  variant="caption"
                  style={{ marginBottom: "6px" }}
                  display="block"
                >
                  NAICS Code
                </Typography>
                <div className="">
                  <Typography variant="body1">{fieldValidate(nfoDrawer["naics"])}</Typography>
                </div>
              </div>
              <div
                style={{
                  paddingBottom: "6px",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
                  width: "100%",
                  marginLeft: '20px'
                }}
              >
                <Typography
                  variant="caption"
                  style={{ marginBottom: "6px" }}
                  display="block"
                >
                  DUNS Code
                </Typography>
                <div className="">
                  {
                    nfoDrawer["duns"] ?
                      <Typography variant="body1">{fieldValidate(nfoDrawer["duns"])}</Typography>
                      :
                      <Typography variant="body1">-</Typography>
                  }

                </div>
              </div>
            </div>
          </ListItem>
          {
            nfoDrawer["uei"] ?
              <CustomField label="UEI Code" data={fieldValidate(nfoDrawer["uei"])} />
              : null
          }
          <CustomField label="sam_link" link={fieldValidate(nfoDrawer["sam_link"])} />
          {
            nfoDrawer["history_link"] ?
              <CustomField label="Beta Link" link={fieldValidate(nfoDrawer["history_link"].split(",")[0])} />
              : null
          }
          <CustomField label="Date" icon={<CalendarTodayOutlinedIcon style={{ color: 'rgba(0, 0, 0, 0.54)' }} />} data={nfoDrawer["award_date"]} />
        </List>
      </Drawer>
      <Backdrop
        className={classes.backdrop}
        open={isOpen}
        onClick={handleDrawerCloseNfo}
      />
    </>
  );
}
