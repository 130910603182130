import React from "react";
import clsx from "clsx";

import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
// import Badge from "@material-ui/core/Badge";
// import Typography from "@material-ui/core/Typography";
// import FormControl from "@material-ui/core/FormControl";
// import Select from "@material-ui/core/Select";
// import MenuItem from "@material-ui/core/MenuItem";

import { makeStyles, fade } from "@material-ui/core/styles";
// import InputLabel from "@material-ui/core/InputLabel";
import SearchIcon from "@material-ui/icons/Search";
import NotificationsIcon from "@material-ui/icons/Notifications";
import MoreIcon from "@material-ui/icons/MoreVert";
import MenuIcon from "@material-ui/icons/Menu";
import { Badge } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  inputRoot: {
    color: "inherit",
  },
  hide: {
    display: "none",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      alignItems: "center",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    marginTop: 0,
    minWidth: 220,
  },
}));

export default function HeaderToolbar({
  setSearchQuery,
  handleDrawerOpen,
  isOpen,
  mobileMenuId,
  handleMobileMenuOpen,
  searchQuery,
}) {
  const classes = useStyles();
  return (
    <Toolbar>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="start"
        className={clsx(classes.menuButton, isOpen && classes.hide)}
      >
        <MenuIcon />
      </IconButton>
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          placeholder="Search for an award"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          inputProps={{ "aria-label": "search" }}
        />
      </div>
      <div className={classes.grow} />
      <div className={classes.sectionDesktop}>
        {/* <IconButton color="inherit">
          <Badge variant="dot" color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton> */}
        {/* <FormControl className={classes.formControl} style={{marginRight:'25px'}}>
          <InputLabel style={{fontSize:'16px'}} id="demo-simple-select-label">Select period</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={age}
						onChange={handleChange}
						
          >
            <MenuItem style={{fontSize:'16px'}} value={1}>This month</MenuItem>
            <MenuItem style={{fontSize:'16px'}} value={3}>3 months</MenuItem>
            <MenuItem style={{fontSize:'16px'}} value={6}>6 months</MenuItem>
            <MenuItem style={{fontSize:'16px'}} value={12}>12 months</MenuItem>
          </Select>
        </FormControl>
        <Typography variant="h6" style={{marginRight:'25px',fontSize:'18px'}}>Total awards count: <b style={{color: "#109CF1"}}>{233}</b></Typography>
        <Typography variant="h6" style={{fontSize:'18px'}}>Total awards amount: <b style={{color: "#109CF1"}}>{10000}$</b></Typography> */}
      </div>
      <div className={classes.sectionMobile}>
        <IconButton
          aria-label="show more"
          aria-controls={mobileMenuId}
          aria-haspopup="true"
          onClick={handleMobileMenuOpen}
          color="inherit"
        >
          <MoreIcon />
        </IconButton>
      </div>
    </Toolbar>
  );
}
